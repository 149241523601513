.root {
  width: 400px !important;
  max-height: 700px !important;
  height: auto;
  padding: 10px;
}

.body {
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

.checked {
  background-color: var(--CL_GRAY_127);
}

.footer {
  margin-top: 15px;
  width: 100%;
  justify-content: space-between;
}

.header {
  margin-bottom: 15px;
  align-items: center;
  padding-left: 16px;
}

.link {
  background-color: transparent;
  text-decoration: underline;
  color: var(--CL_GRAY_217);
  border: none;
}

.link:hover,
.link:active,
.link:focus {
  background-color: transparent !important;
  text-decoration: underline;
  border: none !important;
  color: var(--CL_GRAY_242) !important;
  box-shadow: none !important;
}

.filterList {
  display: flex;
  flex-direction: column;
}
