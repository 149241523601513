.root {
  display: inline-flex;
  width: fit-content;
  position: relative;
  align-items: center;
  margin-bottom: 0px;
  color: black;
}

.label {
  margin-bottom: 0px;
  margin-right: -6px;
  min-width: fit-content;
  padding-right: 12px !important;
  font-size: 12px;
  color: var(--white);
}

.error {
  color: var(--red);
}

.icon {
  position: absolute;
  right: 4px;
  top: 7px;
  cursor: pointer;
  z-index: 999;
}

input[type='text']::-webkit-input-placeholder {
  color: var(--CL_GRAY_242);
}
