.root {
  height: 100%;
  flex-wrap: nowrap;
  padding: 20px 25px 0 25px !important;
  background-color: var(--PAGE_BACKGROUND_COLOR_STANDARD);
}

.logTable {
  margin-bottom: 20px;
}

.title {
  height: 20px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
}

.filterContainer {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: calc(100% / 2);
}

.filterRow {
  height: 60px;
  align-items: center;
  background-color: var(--CL_GRAY_51);
  padding: 0 20px;
  margin-bottom: 20px;
}

.note {
  font-size: 12px;
}

.tablePanel {
  background-color: var(--CL_GRAY_51);
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  height: calc(100% - 290px);
}

.dataContainer {
  background-color: var(--CL_GRAY_51);
  padding: 25px;
  height: calc(100% - 162px);
}

.titleContainer {
  margin-bottom: 18px;
  display: flex;
  justify-content: flex-end;
}

.tailButton {
  width: 150px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  padding: 0 8px;
}

.filterButtonContainer {
  display: flex;
  align-items: center;
}

.filterControl {
  height: calc(1.5em + 0.75rem + 2px);
}
